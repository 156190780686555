<template>
  <div class="d-flex flex-column flex-root">
    <div
      class="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white"
      id="kt_login"
    >
      <!--begin::Aside-->
      <div
        class="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-20 px-lg-40"
      >
        <!--begin: Aside Container -->
        <div class="d-flex flex-row-fluid flex-column justify-content-center">
          <!--begin: Aside header -->
          <a href="#" class="flex-column-auto">
            <img :src="`${baseAppPath}/media/biotrop-logo.png`" height="90px" />
            <!-- <img
              :src="`${baseAppPath}/media/logos/logo-letter-1.png`"
              height="90px"
              width="90px"
            /> -->
          </a>
          <!--end: Aside header -->

          <router-view></router-view>
        </div>
        <!--end: Aside Container -->
      </div>
      <!--begin::Aside-->

      <!--begin::Content-->
      <div
        class="flex-row-fluid d-flex flex-column position-relative overflow-hidden"
      >
        <div
          class="w-100 h-100 bgi-size-cover bgi-no-repeat"
          :style="{ backgroundImage: `url(${backgroundImage})` }"
        ></div>
      </div>
      <!--end::Content-->
    </div>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";

.input-title {
  margin-bottom: 10px;
  font-size: 14px;
  font-style: normal;
  font-weight: bold;
  color: #3e5d4c;
}
</style>

<script>
import { mapState } from "vuex";

export default {
  name: "auth",
  methods: {},
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),
    backgroundImage() {
      return process.env.BASE_URL + "media/login-bg.png";
    }
  }
};
</script>
